<template>
  <div>
    <TableComitati
      @getComitatiList="getComitatiList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TableComitati from "@/components/components-fit/gestione/comitati/TableComitati.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "comitati",
  components: {
    TableComitati,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Comitati");
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(() => store.getters.rowsToSkipComitati);
    const fetchRows = computed(() => store.getters.fetchRowsComitati);
    const sortColumn = computed(() => store.getters.sortColumnComitati);
    const sortOrder = computed(() => store.getters.sortOrderComitati);

    const getComitatiList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.COMITATI_LIST,
        itemName: "comitati_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersComitati");
      getComitatiList();
    };

    getComitatiList();

    return {
      getComitatiList,
      resetFilters,
    };
  },
});
</script>

<style scoped></style>
