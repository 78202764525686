<template>
  <div>
    <div class="row">
      <div class="col-6">
        <div></div>
      </div>
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          comitati di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div v-if="loaded">
      <Datatable
        :table-header="tableHeader"
        :table-data="comitati_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsComitati"
        @current-change="setCurrentPageComitati"
      >
        <template v-slot:cell-comitato="{ row: data }">{{
          data.comitato
        }}</template>
        <template v-slot:cell-territorio="{ row: data }"
          ><div class="tab-long">
            {{ data.territorio }}
          </div></template
        >
        <template v-slot:cell-utenti="{ row: data }">
          {{ data.options }}
          <!--  <button
            class="btn btn-sm dropdown p-1"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_view_comitato"
          >
            <i class="bi bi-eye text-dark fs-5"></i>
          </button> -->
          <button
            class="btn btn-sm dropdown p-1 text-start"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_view_comitato_user"
            @click="getComitatoUtentiList(data.id, data.comitato)"
          >
            <i class="bi bi-people text-dark fs-4"></i>
          </button>
        </template>
      </Datatable>
    </div>
    <!-- <ViewEditComitato /> -->
    <ViewEditUserComitato
      :id_comitato="selectedComitatoId"
      :comitato="selectedComitato"
      @getComitatoUtentiList="getComitatoUtentiList"
    />
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { computed, getCurrentInstance, ref } from "vue";
import { useStore } from "vuex";

//import ViewEditComitato from "./ViewEditComitato.vue";
import ViewEditUserComitato from "./ViewEditUserComitato.vue";
export default {
  name: "TableComitati",
  components: { Datatable, /* ViewEditComitato, */ ViewEditUserComitato },
  emits: ["getComitatiList"],
  setup(props, { emit }) {
    const tableHeader = ref([
      {
        name: "Comitato",
        key: "comitato",
      },
      {
        name: "Territorio",
        key: "territorio",
      },
      {
        name: "Utenti",
        key: "utenti",
        sortable: false,
      },
    ]);
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const setFetchRowsComitati = (e) => {
      store.commit("setFetchRowsComitati", e);
      emit("getComitatiList");
    };
    const setCurrentPageComitati = (page) => {
      store.commit("setCurrentPageComitati", page);
      emit("getComitatiList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnComitati", columnName);
      store.commit("setSortOrderComitati", order);
      emit("getComitatiList");
    };

    const rowsToSkipUtenti = computed(
      () => store.getters.rowsToSkipComitatoUtenti
    );
    const fetchRowsUtenti = computed(
      () => store.getters.fetchRowsComitatoUtenti
    );
    const sortColumnUtenti = computed(
      () => store.getters.sortColumnComitatoUtenti
    );
    const sortOrderUtenti = computed(
      () => store.getters.sortOrderComitatoUtenti
    );

    const selectedComitatoId = ref(null);
    const selectedComitato = ref(null);

    const getComitatoUtentiList = (id, nome) => {
      selectedComitato.value = nome;
      selectedComitatoId.value = id;
      store.dispatch("setStoreListData", {
        keys: {
          id_Comitato: id,
          rowstoskip: rowsToSkipUtenti.value,
          fetchrows: fetchRowsUtenti.value,
          sortcolumn: sortColumnUtenti.value,
          sortorder: sortOrderUtenti.value,
        },
        apiLink: globalApi.COMITATO_UTENTI_LIST,
        itemName: "comitato_utenti_list",
      });
    };

    const resetFiltersUtenti = () => {
      store.commit("resetFiltersComitatoUtenti");
      getComitatoUtentiList();
    };

    return {
      tableHeader,
      comitati_list: computed(() =>
        store.getters.getStateFromName("resultscomitati_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedcomitati_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordcomitati_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statuscomitati_list")
      ),

      currentPage: computed(() => store.getters.currentPageComitati),
      rowsToSkip: computed(() => store.getters.rowsToSkipComitati),
      fetchRows: computed(() => store.getters.fetchRowsComitati),
      sortColumn: computed(() => store.getters.sortColumnComitati),
      sortOrder: computed(() => store.getters.sortOrderComitati),
      setFetchRowsComitati,
      setCurrentPageComitati,
      setSortOrderColumn,
      resetFiltersUtenti,
      getComitatoUtentiList,
      selectedComitato,
      selectedComitatoId,
    };
  },
};
</script>

<style scoped></style>
