<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_view_comitato_user">
    <div class="modal-dialog">
      <div class="modal-content">
        <loading
          :active="isLoading"
          :is-full-page="fullPage"
          :z-index="1100"
        ></loading>
        <div class="modal-header">
          <h5 class="modal-title">Utenti {{ comitato }}</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>
        <div class="modal-body">
          <div class="mx-auto my-auto text-center" v-if="!loaded">
            <div class="spinner-border text-gray-600" role="status"></div>
            <span class="text-gray-600 ps-3 fs-2">Loading</span>
          </div>
          <div
            v-else-if="
              comitato_utenti_list.length === 0 &&
              loaded &&
              (status == 200 || status == 204)
            "
            class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
          >
            <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
            <span class="text-gray-700 ps-3 fs-2">
              Non sono presenti utenti.
            </span>
          </div>
          <div
            v-else-if="status != 200 && status != 204"
            class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
          >
            <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
            <span class="text-gray-700 ps-3 fs-2">
              Attenzione! Si è verificato un errore. Riprovare più tardi.
            </span>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="text-gray-600 pt-4"
            >
              <router-link
                v-if="isEnabled('fnLinkBachecaCompleta')"
                to="/bacheca"
              >
                <i class="bi bi-house fs-2 text-gray-800"> </i
              ></router-link>
              <router-link v-else to="/bacheca-public">
                <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
            ></span>
          </div>
          <div class="card-body p-6" v-else>
            <Datatable
              :table-header="tableHeader"
              :table-data="comitato_utenti_list"
              :total="record"
              :current-page="currentPage"
              :rows-per-page="fetchRows"
              :sortLabel="sortColumn"
              :order="sortOrder"
              @sort="setSortOrderColumn"
              @items-per-page-change="setFetchRowsComitatoUtenti"
              @current-change="setCurrentPageComitatoUtenti"
            >
              <template v-slot:cell-name="{ row: data }">{{
                data.name
              }}</template>
              <template v-slot:cell-mail="{ row: data }">
                <!-- <div style="min-width: 15rem">
                  <input
                    type="text"
                    class="form-control"
                    :value="data.mail"
                    aria-label=""
                  /></div
              > -->{{ data.mail }}</template
              >
              <template v-slot:cell-data_creazione="{ row: data }">
                {{ data.data_creazione }}
              </template>
              <template v-slot:cell-data_ultimo_login="{ row: data }">{{
                data.data_ultimo_login
              }}</template>
            </Datatable>
            <hr v-if="!readOnly" />
            <div class="row pb-4 ps-3">
              <div class="col-md-4 mb-2" v-if="!readOnly">
                <label class="fw-bold text-gray-600">Username</label>
                <input
                  name="username"
                  type="text"
                  class="form-control"
                  v-model="name"
                />
              </div>
              <div class="col-md-4 mb-2" v-if="!readOnly">
                <label class="fw-bold text-gray-600"
                  >E-mail utente
                  <Popper
                    arrow
                    content="L'email di accesso al sistema non potrà essere cambiata."
                  >
                    <i class="bi bi-info-circle-fill text-center"></i> </Popper
                ></label>
                <input
                  name="email_utente"
                  type="text"
                  class="form-control"
                  v-model="email_utente"
                />
              </div>
              <div class="text-start mt-1 col-md-3" v-if="!readOnly">
                <div class="mt-5">
                  <button
                    type="button"
                    class="badge bg-light-success text-success rounded fs-6"
                    @click="addUtente()"
                  >
                    Crea utenza
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer" v-if="!readOnly">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Chiudi
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { computed, ref } from "vue";

import { useStore } from "vuex";

import { addUtenza } from "@/requests/utenzeRequests";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import Popper from "vue3-popper";
import isEnabled from "@/composables/isEnabled.js";

export default {
  name: "table-utenti-un-comitato",
  components: { Datatable, Loading, Popper },
  emits: ["getComitatoUtentiList"],
  props: {
    id_comitato: {
      type: Number,
      required: true,
    },
    comitato: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const tableHeader = ref([
      {
        name: "Utenza",
        key: "name",
      },
      {
        name: "E-mail",
        key: "mail",
      },
      {
        name: "Data creazione",
        key: "data_creazione",
      },
      {
        name: "Ultimo accesso",
        key: "data_ultimo_login",
      },
    ]);

    const setFetchRowsComitatoUtenti = (e) => {
      store.commit("setFetchRowsComitatoUtenti", e);
      emit("getComitatoUtentiList", props.id_comitato, props.comitato);
    };
    const setCurrentPageComitatoUtenti = (page) => {
      store.commit("setCurrentPageComitatoUtenti", page);
      emit("getComitatoUtentiList", props.id_comitato, props.comitato);
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnComitatoUtenti", columnName);
      store.commit("setSortOrderComitatoUtenti", order);
      emit("getComitatoUtentiList", props.id_comitato, props.comitato);
    };

    const regexEmail = ref(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
    const email_utente = ref(null);
    const name = ref(null);
    const isLoading = ref(false);

    const addUtente = () => {
      if (!email_utente.value || !name.value) {
        alertFailed("Compilare i dati richiesti");
        return false;
      } else if (regexEmail.value.test(email_utente.value)) {
        Swal.fire({
          title: "Sei Sicuro?",
          html: "Procedendo verrà creata l'utenza.",
          icon: "warning",
          buttonsStyling: false,
          showCancelButton: true,
          cancelButtonText: "Annulla",
          confirmButtonText: "Crea utenza",
          allowOutsideClick: false,
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
            cancelButton: "btn fw-bold btn-light-warning",
          },
        }).then(async (e) => {
          if (e.isDismissed) {
            return;
          }
          if (e.isConfirmed) {
            isLoading.value = true;
            await addUtenza(
              name.value,
              email_utente.value,
              null,
              props.id_comitato,
              null,
              null,
              null,
              "UTCR"
            ).then((res) => {
              if (res.status == 200) {
                alertSuccess("Utenza creata correttamente");
                emit(
                  "getComitatoUtentiList",
                  props.id_comitato,
                  props.comitato
                );
                email_utente.value = null;
                name.value = null;
              } else {
                alertFailed(
                  res.data.message
                    ? res.data.message
                    : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                );
              }
              isLoading.value = false;
            });
          }
        });
      } else {
        alertFailed("Inserire un email valida");
      }
      return false;
    };

    return {
      tableHeader,
      comitato_utenti_list: computed(() =>
        store.getters.getStateFromName("resultscomitato_utenti_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedcomitato_utenti_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordcomitato_utenti_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statuscomitato_utenti_list")
      ),

      currentPage: computed(() => store.getters.currentPageComitatoUtenti),
      rowsToSkip: computed(() => store.getters.rowsToSkipComitatoUtenti),
      fetchRows: computed(() => store.getters.fetchRowsComitatoUtenti),
      sortColumn: computed(() => store.getters.sortColumnComitatoUtenti),
      sortOrder: computed(() => store.getters.sortOrderComitatoUtenti),
      setFetchRowsComitatoUtenti,
      setCurrentPageComitatoUtenti,
      setSortOrderColumn,

      addUtente,
      email_utente,
      name,
      isLoading,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
};
</script>

<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
